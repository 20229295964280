@font-face {
  font-family: NBOLD;
  src: url(./assets/fonts/NBold.otf);
}
@font-face {
  font-family: NMedium;
  src: url(./assets/fonts/NMedium.otf);
}
@font-face {
  font-family: NRegular;
  src: url(./assets/fonts/NRegular.otf);
}
@font-face {
  font-family: NLight;
  src: url(./assets/fonts/NLight.otf);
}

body {
  margin: 0;
  font-family: "NRegular";
  background-color: #141414;
  user-select: none;
}
#top_buttons {
  opacity: 0 !important;
}

.splide__arrow {
  height: 100%;
  background-color: #14141492;
  width: 5vw;
  outline: 0;
}
.splide__arrow--next {
  right: 0;
  border-radius: 8px 0px 0px 8px;

}

.splide__arrow--prev {
  left: 0;
  border-radius: 0px 8px 8px 0px;

}

.splide__arrow svg{
  fill:#ffffffab
}

.splide__arrow:hover:not(:disabled) svg{
  fill:#ffffff
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 0, 0, 0.604);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 0, 0);
}


#infomodal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#infomodal {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}




